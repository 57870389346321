import React, { memo } from 'react'
import { Box, styled } from '@mui/material'

import InternalLink, {
  InternalLinkProps,
} from '_core/components/atoms/links/InternalLink'
import { termButtonStyles } from '_core/styles/term-button'

interface StyleProps {
  size?: 'xl'
  disabledNormalText?: boolean
}

export interface TermButtonProps extends InternalLinkProps, StyleProps {
  disabled?: boolean
}

const DisabledButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'disabledNormalText',
})<StyleProps>(({ size, disabledNormalText, theme }) => ({
  ...termButtonStyles({ size: size }),
  ...(!disabledNormalText && {
    color: `${theme.palette.textLight} !important`,
  }),
  ['&:hover']: {
    background: 'inherit',
  },
}))

const Button = styled(InternalLink, {
  shouldForwardProp: (prop) => prop !== 'size',
})<StyleProps>(({ size, theme }) => ({
  ...termButtonStyles({ size: size }),
}))

const TermButton = ({ disabled, ...props }: TermButtonProps) => {
  if (disabled) {
    return <DisabledButton {...props} />
  }
  return <Button {...props} />
}

export default memo(TermButton)
