import { useTheme } from '@mui/material'
import { rgba } from 'polished'

interface termButtonStylesProps {
  size?: 'xl'
}

export const termButtonStyles = ({ size }: termButtonStylesProps) => {
  const theme = useTheme()
  const styles = {
    display: 'inline-block',
    border: `1px solid ${theme.palette.outlineDark}`,
    fontSize: '1.4rem',
    color: theme.palette.textDark,
    borderRadius: '500px',
    padding: '0.7rem 1.5rem',
    lineHeight: 1,
    marginRight: '4px',
    marginTop: '2px',
    marginBottom: '2px',
    letterSpacing: 0,
    height: 'auto !important',
    textTransform: 'none',
    ...(size === 'xl' && {
      textTransform: 'uppercase',
      padding: '1rem 1.8rem',
      marginRight: '8px',
      marginTop: '4px',
      marginBottom: '4px',
      borderWidth: '1.4px !important',
      fontWeight: theme.typography.fontWeightSemiBold,
      [theme.breakpoints.up('xl')]: {
        borderWidth: '2px !important',
        padding: '1.5rem 1.8rem',
      },
    }),
    transition: theme.transitions.create([
      'background-color',
      'color',
      'border-color',
    ]),
    ['&:last-child']: {
      marginRight: 0,
    },
    ['&:hover']: {
      color: theme.palette.textDark,
      backgroundColor: rgba(theme.palette.textDark, 0.08),
      borderColor: theme.palette.outlineDark,
    },
    ['&:focus']: {
      backgroundColor: rgba(theme.palette.textDark, 0.25),
    },
  }
  return styles
}
