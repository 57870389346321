import React, { memo, useRef } from 'react'
import { Container, IconButton, styled, useTheme } from '@mui/material'
import { Swiper } from 'swiper/react'
import SwiperCore, { Autoplay, FreeMode, Navigation, Scrollbar } from 'swiper'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/navigation'

import Button from '_core/components/atoms/buttons/Button'

interface Props {
  children: React.ReactNode
}

interface scrollbarPositionProps {
  scrollbarWidth: number
  navSpace: number
  buttonSize: number
  buttonSpace: number
}

const scrollbarWidths = {
  xs: 300,
  md: 480,
  lg: 780,
}

const scrollbarProps = {
  navSpace: 50,
  buttonSize: 64,
  buttonSpace: 6,
}

const getNavContainerWidth = ({
  scrollbarWidth,
  navSpace,
  buttonSize,
  buttonSpace,
}: scrollbarPositionProps): number => {
  const buttonContainerWidth = buttonSize * 2 + buttonSpace
  return scrollbarWidth + buttonContainerWidth + navSpace
}
const getScrollbarPoisition = ({
  scrollbarWidth,
  navSpace,
  buttonSize,
  buttonSpace,
}: scrollbarPositionProps): number => {
  const buttonContainerWidth = buttonSize * 2 + buttonSpace
  return -(scrollbarWidth / 2 + (buttonContainerWidth + navSpace) / 2)
}

const SwiperContainer = styled('div')(({ theme }) => ({
  ['.swiper-cards']: {
    overflow: 'visible',
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(12),
    },
    [theme.breakpoints.up('xl')]: {
      paddingBottom: theme.spacing(14),
    },
  },
  ['.swiper-wrapper']: {
    alignItems: 'stretch',
  },
  ['.swiper-slide']: {
    height: 'auto',
    // display: 'flex',
    // alignItems: 'stretch',
    opacity: 0.4,
    transition: theme.transitions.create(['transition, opacity']),

    ['&:not(:last-child)']: {
      borderRight: `1px solid ${theme.palette.outlineDark}`,
    },
    ['&.swiper-slide-active']: {
      opacity: 1,
    },
    [theme.breakpoints.up('md')]: {
      ['&.swiper-slide-active + .swiper-slide']: {
        opacity: 1,
      },
    },
    [theme.breakpoints.up('lg')]: {
      ['&.swiper-slide-active + .swiper-slide + .swiper-slide']: {
        opacity: 1,
      },
    },
  },
  ['.swiper-horizontal > .swiper-scrollbar']: {
    width: `${scrollbarWidths.xs}px`,
    left: '50%',
    height: '1px',
    backgroundColor: 'rgba(0,0,0,.12)',
    transform: 'translateX(-50%)',
    bottom: `${scrollbarProps.buttonSize / 2}px`,

    [theme.breakpoints.up('md')]: {
      width: `${scrollbarWidths.md}px`,
      transform: `translateX(${getScrollbarPoisition({
        ...scrollbarProps,
        scrollbarWidth: scrollbarWidths.md,
      })}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      width: `${scrollbarWidths.lg}px`,
      transform: `translateX(${getScrollbarPoisition({
        ...scrollbarProps,
        scrollbarWidth: scrollbarWidths.lg,
      })}px)`,
    },
    ['.swiper-scrollbar-drag']: {
      height: '3px',
      marginTop: '-1px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      cursor: 'pointer',
    },
  },
}))

const NavContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: `${getNavContainerWidth({
    ...scrollbarProps,
    scrollbarWidth: scrollbarWidths.xs,
  })}px`,
  [theme.breakpoints.up('md')]: {
    width: `${getNavContainerWidth({
      ...scrollbarProps,
      scrollbarWidth: scrollbarWidths.md,
    })}px`,
  },
  [theme.breakpoints.up('lg')]: {
    width: `${getNavContainerWidth({
      ...scrollbarProps,
      scrollbarWidth: scrollbarWidths.lg,
    })}px`,
  },
  height: `${scrollbarProps.buttonSize}px`,
}))

const NavButton = styled(IconButton)(({ theme }) => ({
  width: `${scrollbarProps.buttonSize}px`,
  height: `${scrollbarProps.buttonSize}px`,
  zIndex: 10,
  display: 'none',
  backgroundColor: theme.palette.common.white,
  transition: theme.transitions.create(['background-color', 'color']),
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  ['&:hover']: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}))

const ScrollbarSwiper = ({ children }: Props) => {
  const theme = useTheme()
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  SwiperCore.use([Scrollbar, Autoplay, FreeMode, Navigation])

  return (
    <Container maxWidth="xl" sx={{ px: { xs: 3, md: 4, lg: 3, xl: 0 } }}>
      <SwiperContainer>
        <Swiper
          onInit={(swiper) => {
            // @ts-ignore
            swiper.params.navigation.prevEl = prevRef.current
            // @ts-ignore
            swiper.params.navigation.nextEl = nextRef.current
            swiper.navigation.init()
            swiper.navigation.update()
          }}
          slidesPerView={1}
          observer={true}
          spaceBetween={0}
          observeParents={true}
          scrollbar={{
            draggable: true,
          }}
          freeMode={{
            enabled: true,
            sticky: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          className="swiper-cards"
          breakpoints={{
            [theme.breakpoints.values.xs]: {
              slidesPerView: 1,
            },
            [theme.breakpoints.values.md]: {
              slidesPerView: 2,
            },
            [theme.breakpoints.values.lg]: {
              slidesPerView: 3,
            },
          }}
        >
          {children}
          <NavContainer>
            <NavButton
              aria-label="previous"
              ref={prevRef}
              sx={{ mr: `${scrollbarProps.buttonSpace}px` }}
            >
              <ArrowBack />
            </NavButton>
            <NavButton aria-label="next" ref={nextRef}>
              <ArrowForward />
            </NavButton>
          </NavContainer>
        </Swiper>
      </SwiperContainer>
    </Container>
  )
}

export default memo(ScrollbarSwiper)
