import React from 'react'
import { graphql } from 'gatsby'
import {
  Box,
  BoxProps,
  Divider,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { useAccount } from 'account/context/AccountContext'
import { useAuthenticatedLocal } from 'account/context/AuthenticatedLocalContext'
import termStructure from '_core/constants/term-structure'
import Flex from '_core/components/atoms/layout/Flex'
import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'
import MicroMandateCard from '_core/components/molecules/cards/mandate/MicroMandateCard'
import TermButtonGroup from '_core/components/molecules/buttonGroups/TermButtonGroup'

import contentComponentSpacing from 'public/constants/content-component-spacing'
import TeamMemberContactCard from 'public/components/molecules/cards/teamMember/TeamMemberContactCard'
import SectionHeading from 'public/components/molecules/sections/SectionHeading'
import PageTitleHero from 'public/components/organisms/hero/PageTitleHero'
import Layout from 'public/components/organisms/global/Layout'
import TwoColContentLayout from 'public/components/organisms/layout/TwoColContentLayout'
import BlogPostScroll from 'public/components/organisms/sections/BlogPostScroll'
import ContentComponents from 'public/components/molecules/contentComponents/ContentComponents'
import SubscribeSection from 'public/components/organisms/sections/SubscribeSection'

import Vote from 'public/components/molecules/vote/Vote'

interface Props {
  data: Queries.DatoCmsBlogDetailQuery
}

const Summary = styled(Box)(({ theme }) => ({
  maxHeight: '400px',
  overflow: 'hidden',
  position: 'relative',
  ['&:after']: {
    content: '""',
    width: '100%',
    height: '100%',
    display: 'block',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
  },
}))

const BlogDetail = ({ data }: Props) => {
  const { state: accountState } = useAccount()
  const { isAuthenticatedLocal } = useAuthenticatedLocal()
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const post = data.datoCmsBlogPost
  const recentPosts = data.allDatoCmsBlogPost.edges

  if (post.blogType.slug === 'market-update') {
    if (typeof window !== 'undefined') {
      window.gtag('event', 'view_market_update', {
        action: 'View Market Update Page',
        title: `${post.title}`,
      })
    }
  }

  // Add terms to the title
  let primaryTerms: string[] = []
  Object.keys(termStructure.primary).map((key, index) => {
    const term: termProps =
      termStructure.primary[key as keyof typeof termStructure.primary]
    post[key].map(
      (
        term:
          | Queries.DatoCmsTermDepartment
          | Queries.DatoCmsTermRegion
          | Queries.DatoCmsTermLevel
          | Queries.DatoCmsTermLocation
      ) => {
        primaryTerms.push(term.name)
      }
    )
  })

  const AuthorsHeading = (props: BoxProps) => (
    <Box
      component="h2"
      sx={{ typography: 'h4', mb: 2, lineHeight: 1, ...props }}
    >
      Authors
    </Box>
  )

  const Authors = (
    <>
      {post.authors.map((author: Queries.DatoCmsTeamMember, index: number) => (
        <div key={author.originalId}>
          <TeamMemberContactCard member={author} />
          {index + 1 < post.authors.length && <Divider sx={{ my: 3 }} />}
        </div>
      ))}
    </>
  )

  const RelatedMandatesHeading = (props: BoxProps) => (
    <Box
      component="h2"
      sx={{
        typography: 'h4',
        mt: contentComponentSpacing.medium,
        mb: 0,
        lineheight: 1,
        ...props,
      }}
    >
      Related Mandates
    </Box>
  )

  const RelatedMandates = (
    <>
      {post.relatedMandates.map(
        (mandate: Queries.DatoCmsPageMandate, index: number) => (
          <div key={mandate.originalId}>
            <MicroMandateCard mandate={mandate} />

            {index + 1 < post.relatedMandates.length && (
              <Divider sx={{ my: { xs: 2, lg: 3 } }} />
            )}
          </div>
        )
      )}
    </>
  )

  const rightCol = (
    <>
      {post.authors && post.authors.length > 0 && (
        <>
          <AuthorsHeading />
          <Divider sx={{ mb: 3 }} />
          {Authors}
        </>
      )}
      {post.relatedMandates && post.relatedMandates.length > 0 && (
        <>
          <RelatedMandatesHeading />
          <Divider sx={{ mt: 2, mb: 3 }} />
          {RelatedMandates}
        </>
      )}
    </>
  )

  const leftCol = (
    <>
      <Box component="h2" sx={{ typography: 'h4', mt: 0 }}>
        {post.heroText}
      </Box>
      <Divider
        sx={{ height: '2px', mt: { ...contentComponentSpacing.medium } }}
      />

      {post.private && !accountState.account && !isAuthenticatedLocal() ? (
        <>
          <Summary>
            <ContentComponents components={post.body} limit={1} />
          </Summary>

          <Box
            width={{ xs: '100%', md: '80%', lg: '70%' }}
            mx="auto"
            py={10}
            textAlign="center"
          >
            <SectionHeading heading="Continue Reading" />
            <Box>
              Please create an account in order to read the full article.
            </Box>
            <InternalButtonLink
              slug="account/sign-up"
              variant="contained"
              color="primary"
              sizeVariant="xl"
              sx={{ mt: 3 }}
              onClick={(e) => {
                if (typeof window !== 'undefined') {
                  window.gtag('event', 'account_signup_button_clicked', {
                    action: 'Sign Up Button Pressed From Private Blog Post',
                    title: post.title,
                  })
                }
              }}
            >
              Sign up
            </InternalButtonLink>
            <Divider sx={{ mt: 4, mb: 3 }} />
            <Flex fullWidth center>
              <Typography variant="smallText" color="textGrey">
                Already have an account?
              </Typography>
              <InternalButtonLink
                slug="account/sign-in"
                variant="outlined"
                sizeVariant="lg"
                colorVariant="outline-dark"
                strongLabel
                sx={{ ml: 2 }}
              >
                Sign in
              </InternalButtonLink>
            </Flex>
          </Box>
        </>
      ) : (
        <>
          <ContentComponents components={post.body} largeText />
          <Divider sx={{ mt: contentComponentSpacing.medium }} />

          <Flex
            justifyContent="flex-start"
            flexDirection={{ xs: 'column', lg: 'row' }}
            alignItems={{ lg: 'flex-start' }}
          >
            <Box
              component="h2"
              sx={{
                typography: 'h4',
                mt: contentComponentSpacing.small,
                mb: { xs: '0px', lg: 1 },
                mr: 2,
                lineHeight: { lg: '4.4rem' },
              }}
            >
              Tags
            </Box>
            <Box mt={contentComponentSpacing.small}>
              <TermButtonGroup
                keyId={post.originalId}
                terms={{
                  departments: post.departments,
                  regions: post.regions,
                  levels: post.levels,
                  locations: post.locations,
                  products: post.products,
                  sectors: post.sectors,
                  languages: post.languages,
                  locations: post.locations,
                  responsibilities: post.responsibilities,
                }}
                termButtonProps={{ size: 'xl' }}
              />
            </Box>
          </Flex>
          <Vote post={post} />

          <Box display={{ xs: 'block', lg: 'none' }}>
            {post.authors && post.authors.length > 0 && (
              <>
                <Divider sx={{ my: contentComponentSpacing.small }} />
                <AuthorsHeading mb={3} />
                {Authors}
              </>
            )}
            {post.relatedMandates && post.relatedMandates.length > 0 && (
              <>
                <Divider sx={{ mt: 2, mb: 3 }} />
                <RelatedMandatesHeading mb={3} />
                {RelatedMandates}
              </>
            )}
          </Box>
        </>
      )}
    </>
  )
  const global = data.datoCmsGlobalContent

  return (
    <Layout
      metadata={{
        seoTags: post.seoMetaTags,
        extraTitle: primaryTerms.join(', '),
        customDescription: post.heroText,
        customImage: {
          src: post.metaImage.gatsbyImageData.images.fallback.src,
          width: 1200,
          height: 630,
        },
      }}
    >
      <PageTitleHero
        mainHeading={post.title}
        smallHeading="blog"
        imagePortrait={post.imagePortrait}
        imageLandscape={post.imageLandscape}
        twoColContentLayout
      />
      <TwoColContentLayout
        leftCol={leftCol}
        rightCol={<>{lgUp && <>{rightCol}</>}</>}
        shareButtonsProps={{
          url: `blog/${post.slug}`,
          contentType: post.blogType.name,
          description: post.title,
          source: 'detail-page',
        }}
      />

      <BlogPostScroll
        posts={recentPosts}
        heading={'Recent Blog Posts'}
        wrapperBoxProps={{
          bgcolor: 'lightGrey',
          position: 'relative',
          zIndex: 10,
        }}
      />
      <SubscribeSection />
    </Layout>
  )
}

export const query = graphql`
  query BlogDetail($slug: String!) {
    datoCmsBlogPost(slug: { eq: $slug }) {
      title
      originalId
      slug
      private
      blogType {
        slug
        name
      }
      publishDate(fromNow: true)
      imageLandscape: mainImage {
        gatsbyImageData(
          width: 1600
          layout: FULL_WIDTH
          imgixParams: {
            ar: "16:5"
            fit: "crop"
            crop: "focalpoint"
            sat: -100
          }
        )
      }
      imagePortrait: mainImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "3:4", fit: "crop", crop: "focalpoint" }
        )
      }
      metaImage: mainImage {
        gatsbyImageData(
          layout: FIXED
          imgixParams: { w: "1200", h: "630", fit: "crop", crop: "focalpoint" }
        )
      }
      heroText
      authors {
        ...teamMemberContact
      }
      body {
        ... on DatoCmsHeading {
          model {
            apiKey
          }
          originalId
          heading
        }
        ... on DatoCmsImage {
          model {
            apiKey
          }
          originalId
          image {
            gatsbyImageData(
              width: 900
              layout: CONSTRAINED
              imgixParams: { w: "1000" }
            )
          }
        }
        ... on DatoCmsContentQuote {
          model {
            apiKey
          }
          name
          originalId
          quote
          jobTitle
          company
        }
        ... on DatoCmsText {
          originalId
          model {
            apiKey
          }
          textNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsYoutubeVideo {
          originalId
          model {
            apiKey
          }
          videoId
        }
      }
      departments {
        name
        slug
        originalId
        model {
          apiKey
        }
        departmentColor {
          red
          green
          blue
        }
      }
      regions {
        name
        slug
        originalId
        model {
          apiKey
        }
      }
      levels {
        name
        slug
        originalId
        model {
          apiKey
        }
      }
      locations {
        name
        slug
        originalId
        model {
          apiKey
        }
      }
      products {
        name
        slug
        originalId
        model {
          apiKey
        }
      }
      sectors {
        name
        slug
        originalId
        model {
          apiKey
        }
      }
      languages {
        name
        slug
        originalId
        model {
          apiKey
        }
      }
      responsibilities {
        name
        slug
        originalId
        model {
          apiKey
        }
      }
      relatedMandates {
        originalId
        slug
        title
        model {
          apiKey
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { slug: { ne: $slug } }
      limit: 10
    ) {
      edges {
        node {
          ...BlogPostCard
        }
      }
    }
    datoCmsGlobalContent {
      mailingListHeading
      mailingListText
      imagePortrait: mailingListBackgroundImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "3:4", fit: "crop", crop: "focalpoint" }
        )
      }
      imageLandscape: mailingListBackgroundImage {
        gatsbyImageData(
          width: 1600
          layout: FULL_WIDTH
          imgixParams: {
            ar: "16:9"
            fit: "crop"
            crop: "focalpoint"
            sat: -100
          }
        )
      }
    }
  }
`
export default BlogDetail
