import React, { memo } from 'react'
import { BoxProps, Container } from '@mui/material'
import { SwiperSlide } from 'swiper/react'

import Section from '_core/components/atoms/layout/Section'

import ScrollbarSwiper from 'public/components/molecules/swipers/ScrollbarSwiper'
import SectionHeading from 'public/components/molecules/sections/SectionHeading'
import BlogPostScrollCard from 'public/components/molecules/cards/blogPost/BlogPostScrollCard'

interface Props {
  posts: Queries.DatoCmsBlogPost[]
  heading: string
  wrapperBoxProps?: BoxProps
}

const BlogPostScrollSection = ({ posts, heading, wrapperBoxProps }: Props) => {
  return (
    <Section overflow="hidden" vSpace {...wrapperBoxProps}>
      <Container>
        <SectionHeading heading={heading} />
      </Container>
      <ScrollbarSwiper>
        {posts.map((post: Queries.DatoCmsBlogPost) => {
          return (
            <SwiperSlide key={`post-${post.node.originalId}`}>
              <BlogPostScrollCard
                keyId={post.node.originalId}
                heading={post.node.title}
                image={post.node.image.gatsbyImageData}
                authors={post.node.authors}
                publishDate={post.node.publishDate}
                slug={post.node.slug}
                apiKey={post.node.model.apiKey}
              />
            </SwiperSlide>
          )
        })}
      </ScrollbarSwiper>
    </Section>
  )
}

export default memo(BlogPostScrollSection)
