import React from 'react'
import { Box, useTheme } from '@mui/material'
import { ArrowRightAlt } from '@mui/icons-material'
import InternalLink from '_core/components/atoms/links/InternalLink'

import Flex from '_core/components/atoms/layout/Flex'

interface Props {
  mandate: Queries.DatoCmsPageMandate
}

const MicroMandateCard = ({ mandate }: Props) => {
  const theme = useTheme()
  return (
    <>
      <InternalLink slug={mandate.slug} apiKey={mandate.model.apiKey}>
        <Box
          sx={{
            // typography: 'h4',
            fontSize: '1.7rem',
            fontFamily: theme.typography.fontFamily,
            mb: 1,
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: 1.6,
          }}
        >
          {mandate.title}
        </Box>
      </InternalLink>
      <InternalLink
        slug={mandate.slug}
        apiKey={mandate.model.apiKey}
        colorVariant="primary"
      >
        <Flex
          alignItems="center"
          fullWidth
          sx={{ typography: 'smallText', letterSpacing: '0.1rem' }}
        >
          <Box component="span" display="block" mr={'3px'}>
            View More
          </Box>
          <ArrowRightAlt fontSize="small" />
        </Flex>
      </InternalLink>
    </>
  )
}

export default MicroMandateCard
