import React, { memo } from 'react'
import {
  GatsbyImage,
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image'
import { Box, Typography } from '@mui/material'

import InternalLink from '_core/components/atoms/links/InternalLink'
import ImageHover from '_core/components/atoms/media/ImageHover'

import TimeAgo from 'public/components/atoms/date/TimeAgo'

interface Props {
  keyId: string
  heading: string
  image: IGatsbyImageData
  authors: Queries.DatoCmsTeamMember[]
  publishDate: Date
  slug: string
  apiKey: string
}

const BlogPostScrollCard = ({
  keyId,
  heading,
  image,
  authors,
  publishDate,
  slug,
  apiKey,
}: Props) => {
  return (
    <Box px={2} sx={{ color: 'textDark' }}>
      <InternalLink slug={slug} apiKey={apiKey} aria-label={heading}>
        <ImageHover>
          <GatsbyImage image={image} alt="" />
        </ImageHover>
      </InternalLink>

      <Typography
        sx={{
          margin: '2.4rem 0 1.2rem',
          textTransform: 'uppercase',
          fontSize: '1.4rem',
          lineHeight: '1.6rem',
          color: 'textGrey',
        }}
      >
        <TimeAgo date={publishDate} />
        &nbsp;
        <Box
          component="span"
          sx={{
            fontSize: '0.8rem',
            lineHeight: '1.6rem',
            verticalAlign: 'middle',
          }}
        >
          &bull;
        </Box>
        &nbsp;
        {authors.map((author: Queries.DatoCmsTeamMember, index: number) => {
          let pre
          if (index > 0) {
            pre = ', '
          }
          return (
            <Box
              component="span"
              color="primary.main"
              key={`post-${keyId}-${author.originalId}`}
            >
              {pre}
              {author.name}
            </Box>
          )
        })}
      </Typography>
      <InternalLink slug={slug} apiKey={apiKey}>
        <Typography
          variant="h3"
          color="textDark"
          sx={{ fontSize: '2.4rem !important', margin: 0, color: 'inherit' }}
        >
          {heading}
        </Typography>
      </InternalLink>
    </Box>
  )
}
export default memo(BlogPostScrollCard)
