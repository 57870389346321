import React, { useState, useEffect } from 'react'
import { Divider, Typography } from '@mui/material'
import { ThumbUp, ThumbDown } from '@mui/icons-material'

import { useAuthenticatedLocal } from 'account/context/AuthenticatedLocalContext'
import { useAccount } from 'account/context/AccountContext'
import Flex from '_core/components/atoms/layout/Flex'
import Button, { ButtonProps } from '_core/components/atoms/buttons/Button'

import contentComponentSpacing from 'public/constants/content-component-spacing'

interface Props {
  post: Queries.DatoCmsBlogPost
}

const Vote = ({ post }: Props) => {
  const { state: accountState, update } = useAccount()
  const { isAuthenticatedLocal } = useAuthenticatedLocal()

  const buttonProps: Omit<ButtonProps, 'children'> = {
    sizeVariant: 'xl',
    round: true,
    colorVariant: 'outline-dark',
    variant: 'outlined',
  }

  const [buttonPropsUp, setButtonPropsUp] = useState({ ...buttonProps })
  const [buttonPropsDown, setButtonPropsDown] = useState({ ...buttonProps })

  const handleVoteClick = (direction: 'up' | 'down') => {
    const queryObj = { post: { id: parseInt(post.originalId) } }
    update({ type: `${direction}VotePost`, variables: queryObj }).then(() => {
      if (typeof window !== 'undefined') {
        window.gtag('event', 'market_update_vote', {
          action: `Thumbs ${direction}`,
          title: post.title,
        })
      }
    })
  }

  useEffect(() => {
    if (
      accountState &&
      accountState.votes &&
      Array.isArray(accountState.votes)
    ) {
      const buttonPropsActive: Omit<ButtonProps, 'children' | 'sizeVariant'> = {
        variant: 'contained',
        color: 'primary',
        colorVariant: 'contained-primary-disabled',
        disabled: true,
      }

      accountState.votes.some((item) => {
        if (item.id == post.originalId) {
          if (item.vote === 'up') {
            setButtonPropsUp({
              ...buttonPropsUp,
              ...buttonPropsActive,
            })
            setButtonPropsDown({
              ...buttonProps,
            })
          }
          if (item.vote === 'down') {
            setButtonPropsDown({
              ...buttonPropsUp,
              ...buttonPropsActive,
            })
            setButtonPropsUp({
              ...buttonProps,
            })
          }
        }
      })
    }
  }, [accountState])

  return (
    <>
      {post.blogType.slug === 'market-update' &&
        isAuthenticatedLocal() &&
        accountState.account && (
          <>
            <Divider sx={{ mt: contentComponentSpacing.medium }} />
            <Flex alignCenter mt={4}>
              <Typography
                component="h2"
                variant="h4"
                mr={2}
                lineHeight={{ lg: '4.4rem' }}
              >
                Vote
              </Typography>
              <Button
                {...buttonPropsUp}
                aria-label="Thumbs Up"
                onClick={() => handleVoteClick('up')}
              >
                <ThumbUp style={{ fontSize: '1.7rem' }} />
              </Button>
              <Button
                {...buttonPropsDown}
                aria-label="Thumbs Down"
                onClick={() => handleVoteClick('down')}
                sx={{ ml: 1 }}
              >
                <ThumbDown style={{ fontSize: '1.7rem' }} />
              </Button>
            </Flex>
          </>
        )}
    </>
  )
}

export default Vote
