import React, { memo } from 'react'
import { darken } from 'polished'

import TermButton, {
  TermButtonProps,
} from '_core/components/atoms/buttons/termButtons/TermButton'

export interface TermButtonGroupProps {
  keyId: string
  isDynamic?: true
  terms: {
    departments?: Queries.DatoCmsTermDepartment[]
    regions?: Queries.DatoCmsTermRegion[]
    levels?: Queries.DatoCmsTermLevel[]
    locations?: Queries.DatoCmsTermLocation[]
    languages?: Queries.DatoCmsTermLanguage[]
    secondaryDepartments?: Queries.DatoCmsTermDepartment[]
    secondaryRegions?: Queries.DatoCmsTermRegion[]
    products?: Queries.DatoCmsTermProduct[]
    sectors?: Queries.DatoCmsTermSector[]
    responsibilities?: Queries.DatoCmsTermResponsibility[]
  }
  termButtonProps?: Omit<TermButtonProps, 'slug'>
}

const TermButtonGroup = ({
  keyId,
  isDynamic,
  terms,
  termButtonProps,
}: Props) => {
  return (
    <>
      {Object.keys(terms).map((key) => {
        const termItems = terms[key as keyof typeof terms]
        if (termItems && termItems.length > 0) {
          return termItems.map(
            (
              term:
                | Queries.DatoCmsTermDepartment
                | Queries.DatoCmsTermRegion
                | Queries.DatoCmsTermLevel
                | Queries.DatoCmsTermLocation
                | Queries.DatoCmsTermLanguage
                | Queries.DatoCmsTermProduct
                | Queries.DatoCmsTermSector
                | Queries.DatoCmsTermResponsibility
            ) => {
              let departmentSx = {}
              if (term.departmentColor) {
                const dc = term.departmentColor
                departmentSx = {
                  sx: {
                    color: `rgb(${dc.red}, ${dc.green}, ${dc.blue})`,
                    borderColor: `rgba(${dc.red}, ${dc.green}, ${dc.blue}, 0.5) !important`,
                    ['&:hover']: {
                      color: darken(
                        0.1,
                        `rgb(${dc.red}, ${dc.green}, ${dc.blue})`
                      ),
                      backgroundColor: `rgba(${dc.red}, ${dc.green}, ${dc.blue}, 0.2)`,
                    },
                  },
                }
              }
              return (
                <TermButton
                  key={`${keyId}-${term.slug}`}
                  slug={term.slug}
                  apiKey={isDynamic ? term._modelApiKey : term.model.apiKey}
                  {...departmentSx}
                  {...termButtonProps}
                >
                  {term.name}
                </TermButton>
              )
            }
          )
        }
      })}
    </>
  )
}

export default memo(TermButtonGroup)
