import React, { memo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import axios from 'axios'
import { Box, Container, styled, useTheme } from '@mui/material'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'

import { axiosDefaults } from '_core/helpers/axios'
import { useLoading } from '_core/context/LoadingContext'
import { useMessage } from '_core/context/MessageContext'
import Button from '_core/components/atoms/buttons/Button'
import Section from '_core/components/atoms/layout/Section'
import FormikTextField from '_core/components/atoms/inputs/formik/FormikTextField'

import BackgroundPlx from 'public/components/atoms/plx/BackgroundPlx'
import BlogIconBase from 'public/images/icons/blog.svg'

interface FormValues {
  firstName: string
  lastName: string
  email: string
}

interface InputValues {
  input: FormValues
}

const BlogIcon = styled(BlogIconBase)`
  width: 53px;
  display: block;
  margin: 0 auto;
`

const ImageContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '120%',
  [theme.breakpoints.down('lg')]: {
    height: '200%',
  },
  ['.gatsby-image-wrapper']: {
    backgroundColor: theme.palette.textDark,
    width: '100% !important',
    height: '100% !important',
    [theme.breakpoints.down('lg')]: {
      paddingTop: '133.3333333333333%',
    },
  },
}))
const StyledGatsbyImage = styled(GatsbyImage)(({ theme }) => ({
  backgroundColor: theme.palette.textDark,
  opacity: 0.14,
  width: '100% !important',
  height: '100% !important',
  // [theme.breakpoints.down('md')]: {
  //   paddingTop: '200%',
  // },
}))

const SubscribeSection = () => {
  const { dispatch: loadingDispatch } = useLoading()
  const { dispatch: messageDispatch } = useMessage()
  const theme = useTheme()

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
  }
  const validationSchema = object().shape({
    firstName: string().required('First Name is Required'),
    lastName: string().required('Last Name is Required'),
    email: string()
      .email('Please supply a valid email address')
      .required('Email address is Required'),
  })

  const joinMailingList = async (variables: InputValues) => {
    loadingDispatch({
      type: 'START_LOADING',
    })
    let statusObj = {}
    const query = `
      mutation ($input: JoinMailingListInput!) {
        joinMailingList (input: $input){
          success
        }
      }
    `

    const dataObj = {
      query: query,
      variables: variables,
    }

    try {
      await axios({ ...axiosDefaults(), data: dataObj })
      messageDispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: 'You have successfully joined our mailing list',
          status: 'success',
        },
      })
      loadingDispatch({
        type: 'STOP_LOADING',
      })
      return
    } catch (error) {
      console.log('error:', error)
      messageDispatch({
        type: 'SET_MESSAGE',
        payload: {
          message:
            'There was an error when trying to join our mailing list. Please try again.',
          status: 'error',
        },
      })
      loadingDispatch({
        type: 'STOP_LOADING',
      })
      return
    }
  }

  const handleSubmit = (values: FormValues) => {
    let variables = {
      input: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      },
    }
    joinMailingList(variables)
  }

  const data = useStaticQuery<Queries.SubscribeQuery>(graphql`
    query Subscribe {
      datoCmsGlobalContent {
        mailingListHeading
        mailingListText
        imagePortrait: mailingListBackgroundImage {
          gatsbyImageData(
            width: 900
            layout: FULL_WIDTH
            imgixParams: { ar: "3:4", fit: "crop", crop: "focalpoint" }
          )
        }
        imageLandscape: mailingListBackgroundImage {
          gatsbyImageData(
            width: 1600
            layout: FULL_WIDTH
            imgixParams: { ar: "16:9", fit: "crop", crop: "focalpoint" }
          )
        }
      }
    }
  `)
  const global = data.datoCmsGlobalContent

  // @ts-ignore
  const images = withArtDirection(getImage(global.imageLandscape), [
    {
      media: `(max-width: ${theme.breakpoints.values.lg}px)`,
      image: getImage(global.imagePortrait),
    },
  ])

  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: -50,
          endValue: 50,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  return (
    <>
      <Section
        bgcolor="secondary.main"
        color="rgba(255,255,255,.5)"
        py={{ xs: 4, sm: 5, xl: 10 }}
        overflow="hidden"
      >
        <BackgroundPlx parallaxData={parallaxData}>
          <ImageContainer>
            <StyledGatsbyImage image={images} alt="" />
          </ImageContainer>
        </BackgroundPlx>
        <Container maxWidth="sm" sx={{ position: 'relative' }}>
          <Box textAlign="center">
            <BlogIcon />
            <Box
              component="h2"
              sx={{ typography: 'h4', color: 'common.white', my: 3 }}
            >
              {global?.mailingListHeading}
            </Box>
            <Box component="p" mb={3}>
              {global?.mailingListText}
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values: FormValues) => {
                handleSubmit(values)
              }}
            >
              <Form>
                <FormikTextField
                  name="firstName"
                  label="First Name"
                  type="text"
                  shrinkInner
                  fullWidth
                  grey
                  caps
                />
                <FormikTextField
                  name="lastName"
                  label="Last Name"
                  type="text"
                  fullWidth
                  shrinkInner
                  grey
                  caps
                  sx={{ mt: { xs: 2 } }}
                />
                <FormikTextField
                  name="email"
                  label="Email Address"
                  placeholder="Email Address"
                  type="email"
                  fullWidth
                  shrinkInner
                  grey
                  caps
                  sx={{ mt: { xs: 2 } }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  sizeVariant="xxl"
                  type="submit"
                  sx={{ mt: { xs: 3 } }}
                >
                  Subscribe
                </Button>
              </Form>
            </Formik>
          </Box>
        </Container>
      </Section>
    </>
  )
}

export default memo(SubscribeSection)
